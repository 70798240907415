<template>
  <div class="global-box">
    <div class="header">
      <img class="logo" src="../../assets/title.svg"/>
      <a-button class="nav-btn" @click="goBack">返回首页</a-button>
      <a-button type="danger" class="nav-btn" @click="quit">退出登录</a-button>
    </div>
    <router-view class="main"></router-view>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { Modal } from "ant-design-vue";
import { createVNode} from "vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
export default {
  setup() {
    //声明组合式API中使用路由
    const router = useRouter();

    //返回首页
    let goBack = () => {
      router.push({
        path: '/home'
      });
    };

    //浏览魅力东疆
    let visitCharm = () => {
      router.push({
        path: '/touristCharmingEastMobile'
      });
    };

    return {
      goBack,
      visitCharm
    }
  },
  methods: {
    quit() {
      let that = this;
      Modal.confirm({
        title: "请确认是否退出登录？",
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode("div", { style: "color: red;" }, ""),
        okText: "确定",
        cancelText: "取消",
        onOk() {
          let api = "http://39.100.158.75:8080/user/logout";
          that
            .$axios({
              url: api,
              method: "post",
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
              params: {
                token: that.$storage.get("userinfo").data.token,
              },
            })
            .then((response) => {
              message.info(response.data.message);
              that.$storage.remove("userinfo");
              that.$router.push({
                path: "/login",
              });
            });
        },
        onCancel() {},
      });
    }
  }
}
</script>

<style lang="scss" scoped>
/* 竖屏 */
@media screen and (orientation:portrait) {
  .global-box {
    position: relative;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    .header {
      display: flex;
      align-items: center;
      margin-top: 0.1rem;
      margin-bottom: 0.1rem;
      height: 5%;
      .logo {
        width: 2rem;
      }
      .nav-btn {
        margin-left: 0.2rem;
      }
    }
    .main {
      height: calc(100% - 5% - 0.2rem);
    }
  }  
}
/* 横屏 */
@media screen and (orientation:landscape) {
  .global-box {
    position: relative;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    .header {
      display: flex;
      align-items: center;
      margin-top: 0.1rem;
      margin-bottom: 0.1rem;
      height: 10%;
      .logo {
        width: 2rem;
      }
      .nav-btn {
        margin-left: 0.2rem;
      }
    }
    .main {
      height: calc(100% - 10% - 0.2rem);
    }
  }
}
</style>
